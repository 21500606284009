<template>
  <div class="supplier-list">
    <CommonTable :columns="columns" :dataSource="tableData" :total="total">
      <!-- <template v-slot:amountWithoutTax="{ text, record, index }"></template> -->
    </CommonTable>
  </div>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
import CommonTable from '@/component/commonTable'
export default {
  name: 'SupplierList',
  components: { CommonTable },
  data() {
    return {
      tableData: [],
      total: 0,
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('名称'),
          dataIndex: 'supplier_name',
        },
        {
          title: this.$t('联系方式'),
          dataIndex: 'mobile',
        },
        {
          title: this.$t('地址'),
          dataIndex: 'address',
          customRender: (text, record) => {
            return `${record.province}${record.city}${record.direct}${record.address}`
          },
        },
        {
          title: this.$t('银行名称'),
          dataIndex: 'bank_name',
        },
        {
          title: this.$t('银行账号'),
          dataIndex: 'bank_account',
        },
      ]
    },
  },
  methods: {
    getList() {
      http({
        url: api.queryPurchaseSupplierList,
        success: (res) => {
          this.tableData = res.result.list
          this.total = res.result.total
        },
      })
    },
  },
}
</script>
